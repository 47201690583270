import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCGDbmhW7aw5h-lDP8RSX8HG6Ttlo0slmM",
  authDomain: "floridade-web.firebaseapp.com",
  projectId: "floridade-web",
  storageBucket: "floridade-web.appspot.com",
  messagingSenderId: "321954774474",
  appId: "1:321954774474:web:cc640b09278655b249e333",
  databaseURL: "https://floridade-web-default-rtdb.firebaseio.com",
};

const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const auth = getAuth(app);
